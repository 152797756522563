import { css, ElementStyles } from '@microsoft/fast-element';
import { AccordionItemOptions, FoundationElementTemplate } from '@microsoft/fast-foundation';
import { accordionItemStyles } from '../accordion-item/accordion-item.styles';
import { designTokens } from '../../design-tokens';

export const accordionAnchorStyles: FoundationElementTemplate<ElementStyles, AccordionItemOptions> = (
    context,
    definition
) => css`
    ${accordionItemStyles(context, definition)}

    .button {
        text-decoration: none;
        margin: 0em;
        display: flex;
        align-items: center;
    }
`;
