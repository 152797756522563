import { navBarTemplate } from './nav-bar.template';
import { navBarStyles } from './nav-bar.styles';
import { attr, Observable, observable } from '@microsoft/fast-element';
import { MediaSize, MediaSizeMixin, mediaSizes } from '../../utilities/media-size-observer';
import { FoundationElement } from '@microsoft/fast-foundation';
import { NatGenContainer } from '../container/container';

/**
 * @element natgen-nav-bar
 */
export class NatGenNavBar extends MediaSizeMixin(FoundationElement) {
    @attr({mode: 'boolean', attribute: 'no-collapse' }) noCollapse = false;
    @attr({mode: 'boolean', attribute: 'container' }) useContainer = false;
    @attr breakpoint: MediaSize = 'large';

    @observable expand = false;

    isExpanded(): boolean {
        return this.expand || this.noCollapse;
    }

    breakpointChanged() {
        this.expand = mediaSizes.indexOf(this.mediaSize) >= mediaSizes.indexOf(this.breakpoint);
    }

    mediaSizeChanged() {
        this.breakpointChanged();
    }

    connectedCallback(): void {
        super.connectedCallback();
    }

    disconnectedCallback(): void {
        super.disconnectedCallback();
    }
}

export const natGenNavbar = NatGenNavBar.compose({
    baseName: 'nav-bar',
    template: navBarTemplate,
    styles: navBarStyles
});
