import { AccordionItem, AccordionItemOptions } from "@microsoft/fast-foundation";
import { attr } from "@microsoft/fast-element";
import { accordionAnchorTemplate } from "./accordion-anchor.template";
import { accordionAnchorStyles } from "./accordion-anchor.styles";

/**
 * @element natgen-accordion-anchor
 */
export class NatGenAccordionAnchor extends AccordionItem {
    @attr
    href: string;

    @attr
    target: string;
}

export const natGenAccordionAnchor = NatGenAccordionAnchor.compose<AccordionItemOptions>({
    baseName: 'accordion-anchor',
    template: accordionAnchorTemplate,
    styles: accordionAnchorStyles
});
