import { Switch, SwitchOptions, switchTemplate as template } from "@microsoft/fast-foundation";
import { switchStyles as styles } from "./switch.styles";

/**
 * @element natgen-switch
 */
export const natGenSwitch = Switch.compose<SwitchOptions>({
    baseName: "switch",
    template,
    styles,
    switch: `
        <span class="checked-indicator" part="checked-indicator"></span>
    `,
});

export { Switch };

export { styles as switchStyles };
