import { AccordionItem, AccordionItemOptions, accordionItemTemplate } from "@microsoft/fast-foundation";
import { minusIcon, plusIcon } from "../../styles/icons";
import { accordionItemStyles } from "./accordion-item.styles";

/**
 * @element natgen-accordion-item
 */
export class NatGenAccordionItem extends AccordionItem { }

export const natGenAccordionItem = NatGenAccordionItem.compose<AccordionItemOptions>({
    baseName: 'accordion-item',
    template: accordionItemTemplate,
    styles: accordionItemStyles,
    collapsedIcon: plusIcon,
    expandedIcon: minusIcon
});
