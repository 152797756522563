import { html, ViewTemplate } from '@microsoft/fast-element';
import { FoundationElementTemplate } from '@microsoft/fast-foundation';
import { NatGenContainer } from './container';

export const containerTemplate: FoundationElementTemplate<ViewTemplate<NatGenContainer>> = (
    context,
    definition
) => html`
    <template>
        <section class="container" part="container">
            <slot></slot>
        </section>
    </template>
`;
