import { html, ViewTemplate } from '@microsoft/fast-element';
import { FoundationElementTemplate } from '@microsoft/fast-foundation';
import { NatGenSideBar } from './side-bar';

export const sideBarTemplate: FoundationElementTemplate<ViewTemplate<NatGenSideBar>> = (
    context,
    definition
) => html`
    <template role="navigation">
        <slot></slot>
    </template>
`;
