import { css, ElementStyles } from '@microsoft/fast-element';
import { display, FoundationElementTemplate } from '@microsoft/fast-foundation';
import { spacingNumber } from '../../styles/size';

export const stackStyles: FoundationElementTemplate<ElementStyles> = (
    context,
    definition
) => css`
    ${display('block')}

    :host {
        display: flex;
        flex-direction: column;
        gap: calc(${spacingNumber} * 1px);
    }

    :host([appearance=compact]) {
        gap: 0;
    }

    :host([wrap]) {
        flex-wrap: wrap;
    }

    :host([direction=row]) {
        flex-direction: row;
    }

    :host([direction=row-reverse]) {
        flex-direction: row-reverse;
    }

    :host([direction=column-reverse]) {
        flex-direction: column-reverse;
    }

    :host([alignment=center]) {
        justify-content: center;
    }

    :host([alignment=end]) {
        justify-content: flex-end;
    }
`;