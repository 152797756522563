import { css, ElementStyles } from '@microsoft/fast-element';
import { display, FoundationElementTemplate } from '@microsoft/fast-foundation';
import { designTokens } from '../../design-tokens';
import { mediaSizeBehavior } from '../../utilities/media-size-observer';

export const containerStyles: FoundationElementTemplate<ElementStyles> = (
    context,
    definition
) => css`
    ${display('block')}

    :host {
        width: 100%;
    }

    .container {
        width: 100%;
        margin-left: auto;
        margin-right: auto;        
    }
`.withBehaviors(
    mediaSizeBehavior('small',
        css`
            .container {
                width: calc(${designTokens['space-breakpoint-small'].token} * 1px);
            }
        `
    ),
    mediaSizeBehavior('medium',
        css`
            .container {
                width: calc(${designTokens['space-breakpoint-medium'].token} * 1px);
            }
        `
    ),
    mediaSizeBehavior('large',
        css`
            .container {
                width: calc(${designTokens['space-breakpoint-large'].token} * 1px);
            }
        `
    ),
    mediaSizeBehavior('extra-large',
        css`
            .container {
                width: calc(${designTokens['space-breakpoint-extra-large'].token} * 1px);
            }
        `
    ),
);
