import { css, ElementStyles } from '@microsoft/fast-element';
import { FoundationElementTemplate } from '@microsoft/fast-foundation';
import { neutralForegroundRest, neutralLayer1, neutralStrokeRest, designTokens } from '../../design-tokens';
import { heightNumber, paddingNumber } from '../../styles/size';
import { mediaSizeBehavior } from '../../utilities/media-size-observer';

export const navBarStyles: FoundationElementTemplate<ElementStyles> = (
    context,
    definition
) => css`
    :host {
        display: flex;
        justify-content: center;
        background: ${neutralLayer1};
        color: ${neutralForegroundRest};
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;

        box-sizing: border-box;
        padding: 0 calc(${paddingNumber} * 1px);
        width: 100%;
        height: calc(${heightNumber} * 2px);
    }

    natgen-hamburger-button::part(overlay) {
        top: calc(${heightNumber} * 2px);
        height: calc(100vh - (${heightNumber} * 2px));
    }

    ::slotted(natgen-side-bar), natgen-side-bar {
        position: absolute;
        height: 100%;
        z-index: 1;
    }

    natgen-side-bar.default {
        border-right: calc(${designTokens['space-stroke-width'].token} * 1px) solid ${neutralStrokeRest};
        padding: calc(${paddingNumber} * 1px);
    }

    nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;

        width: auto;
        height: 100%;
    }

    .main-menu {
        justify-self: flex-start;
    }

    .logo {
        margin: 0 auto;
    }

    .end {
        justify-self: flex-end;
    }
`.withBehaviors(
    mediaSizeBehavior('small',
        css`
            :host([container]) .container {
                width: calc(${designTokens['space-breakpoint-small'].token} * 1px);
            }
        `
    ),
    mediaSizeBehavior('medium',
        css`
            :host([container]) .container {
                width: calc(${designTokens['space-breakpoint-medium'].token} * 1px);
            }
        `
    ),
    mediaSizeBehavior('large',
        css`
            :host([container]) .container {
                width: calc(${designTokens['space-breakpoint-large'].token} * 1px);
            }
        `
    ),
    mediaSizeBehavior('extra-large',
        css`
            :host([container]) .container {
                width: calc(${designTokens['space-breakpoint-extra-large'].token} * 1px);
            }
        `
    ),
);
