import { ValueConverter } from '@microsoft/fast-element';

/**
 * A {@link ValueConverter} that serializes attributes to JSON string values.
 * @public
 */
export const jsonConverter: ValueConverter = {
    toView(value: any): string {
        return JSON.stringify(value);
    },

    fromView(value: string): any {
        if(value === null || value === undefined)
            return value;

        return JSON.parse(value);
    }
}
