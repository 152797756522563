import { CSSDesignToken, DesignToken, DesignTokenValue } from '@microsoft/fast-foundation';

export class NatGenDesignToken<T extends string | number | boolean | BigInteger | null | Array<any> | symbol | {}> {
    defaultValue: DesignTokenValue<T>;
    name: string;
    token: CSSDesignToken<T>;

    constructor(init?: Partial<NatGenDesignToken<T>>) {
        Object.assign(this, init);
    }

    create() {
        this.token = DesignToken.create<T>(this.name).withDefault(this.defaultValue);
    }

    setValueFor(element: HTMLElement, value: DesignTokenValue<T>) {
        if (this.token == null)
            throw `Token ${this.name} must be created before updated.`;

        this.token.setValueFor(element, value);
    }

    getValueFor(element: HTMLElement) : DesignTokenValue<T> {
        return this.token.getValueFor(element);
    }

    deleteValueFor(element: HTMLElement) {
        return this.token.deleteValueFor(element);
    }
}
