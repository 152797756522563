import { attr, DOM, html } from "@microsoft/fast-element";
import { FoundationElement } from "@microsoft/fast-foundation";
import { NatGenPortalOutlet } from "./portal-outlet";

/**
 * @element natgen-portal
 */
export class NatGenPortal extends FoundationElement {
    @attr({ attribute: 'outlet-selector' }) outletSelector : string;

    observer: MutationObserver;

    connectedCallback(): void {
        this.observer = new MutationObserver(() => this.onContentChanged());
        this.observer.observe(this, { childList: true });

        DOM.queueUpdate(() => {
            this.getOutlet()?.attach(this);
        });
    }    

    disconnectedCallback(): void {
        this.observer.disconnect();
        this.getOutlet().detach(this);        
    }

    onContentChanged() : void {
        this.getOutlet().onPortalUpdate();
    }

    protected getOutlet() : NatGenPortalOutlet {
        var outlet = document.querySelector(this.outletSelector);

        if (!(outlet instanceof NatGenPortalOutlet)) {
            console.warn(`NatGenPortal outlet-selector (${this.outletSelector}) must point to a NatGenPortalOutlet.`);
            return;
        }

        return outlet;
    }
}

export const natGenPortal = NatGenPortal.compose({
    baseName: 'portal',
    template: html<NatGenPortal>`
        <slot></slot>
    `
});