import { css, ElementStyles } from '@microsoft/fast-element';
import { display, FoundationElementTemplate, RadioOptions } from '@microsoft/fast-foundation';
import { designTokens } from '../../design-tokens';
import { baseRadioStyles } from '../../styles/patterns/radio';
import { heightNumber } from '../../styles/size';

export const radioStyles: FoundationElementTemplate<ElementStyles, RadioOptions> = (
    context,
    definition
) =>
    css`
    ${display("inline-flex")} :host {
        --input-size: calc((${heightNumber} / 2) + (${designTokens['space-stroke-width'].token} * 2));
        align-items: center;
        outline: none;
        user-select: none;
        position: relative;
        flex-direction: row;
        transition: all 0.2s ease-in-out;
    }

    ${baseRadioStyles}
`;
