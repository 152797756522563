import type { ViewTemplate } from "@microsoft/fast-element";
import { html, ref, slotted, when } from "@microsoft/fast-element";
import { FoundationElementTemplate } from '@microsoft/fast-foundation';
import { NatGenDialog } from './dialog';

export const dialogTemplate: FoundationElementTemplate<ViewTemplate<NatGenDialog>> = (
    context,
    definition
) => html`
    <div class="positioning-region" part="positioning-region">
        ${when(
            x => x.modal,
            html<NatGenDialog>`
                <div
                    class="overlay"
                    part="overlay"
                    role="presentation"
                    @click="${x => x.dismiss()}"
                ></div>
            `
        )}
        <div
            role="dialog"
            tabindex="-1"
            class="control"
            part="control"
            aria-modal="${x => x.modal}"
            aria-describedby="${x => x.ariaDescribedby}"
            aria-labelledby="${x => x.ariaLabelledby}"
            aria-label="${x => x.ariaLabel}"
            ${ref("dialog")}
        >
            <div>
                <slot></slot>
            </div>
            <div
                class="actions"
                part="actions"
            >
                <slot name="actions" ${slotted('slottedActionNodes')}></slot>
            </div>
        </div>
    </div>
`;
