import { css, ElementStyles } from "@microsoft/fast-element";
import { FoundationElementTemplate } from "@microsoft/fast-foundation";
import { fillColor, designTokens } from '../../design-tokens';
import { elevationRule } from '../../styles/elevation';
import { paddingNumber } from '../../styles/size';

export const dialogStyles: FoundationElementTemplate<ElementStyles> = (
    context,
    definition
) => css`
    :host([hidden]) {
        display: none;
    }

    :host {
        --elevation: 14;
        --dialog-height: 120px;
        --dialog-width: 240px;
        display: block;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.3);
        touch-action: none;
    }

    .positioning-region {
        display: flex;
        justify-content: center;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: auto;
    }

    .control {
        display: flex;
        flex-direction: column;
        ${elevationRule}
        margin-top: auto;
        margin-bottom: auto;
        padding: calc(${paddingNumber} * 1px);
        min-width: var(--dialog-width);
        min-height: var(--dialog-height);
        background-color: ${fillColor};
        z-index: 1;
        border-radius: calc(${designTokens['space-corner-radius'].token} * 1px);
        border: calc(${designTokens['space-stroke-width'].token} * 1px) solid transparent;
    }

    .actions {
        display: flex;
        justify-content: flex-end;
        margin-top: auto;
    }
`;
