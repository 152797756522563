import { css, ElementStyles } from '@microsoft/fast-element';
import { FoundationElementTemplate } from '@microsoft/fast-foundation';
import { accentFillRest, designTokens } from '../../design-tokens';

export const hamburgerButtonStyles: FoundationElementTemplate<ElementStyles> = (
    context,
    definition
) => css`
    .overlay {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
    }

    .nav-button {
        fill: ${accentFillRest};
        color: ${accentFillRest};
        width: calc(${designTokens['space-design-unit'].token} * 6px);
    }

    .nav-button svg {
        width: 100%;
    }

    :host(.opened) .overlay {
        display: initial;
    }
`;
