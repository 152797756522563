import { css, ElementStyles } from '@microsoft/fast-element';
import { display, FoundationElementTemplate } from '@microsoft/fast-foundation';
import { designTokens } from '../../design-tokens';

export const formFieldStyles: FoundationElementTemplate<ElementStyles> = (
    context,
    definition
) => css`
    ${display('block')} :host {
        width: 100%;
        text-align: center;
    }

    :host([orientation="left"]) {
        text-align: left;
    }

    :host([orientation="right"]) {
        text-align: right;
    }
    
    natgen-tooltip {
        vertical-align: text-bottom;
    }

    .control {
        margin-top: calc(${designTokens['space-design-unit'].token} * ${designTokens['space-form-label-gap-units'].token} * 1px);
    }
`;
