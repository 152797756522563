import { html, ViewTemplate } from '@microsoft/fast-element';
import { FoundationElementTemplate } from '@microsoft/fast-foundation';
import { NatGenLoader } from './loader';

export const loaderTemplate: FoundationElementTemplate<ViewTemplate<NatGenLoader>> = (
    context,
    definition
) => html`
    <div></div>
    <div></div>
    <div></div>
`;
