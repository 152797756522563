import { css, ElementStyles } from '@microsoft/fast-element';
import { display, FoundationElementTemplate } from '@microsoft/fast-foundation';
import { accentFillRest, designTokens } from '../../design-tokens';

export const progressBarStyles: FoundationElementTemplate<ElementStyles> = (
    context,
    definition
) => css`
    ${display('inline-flex')}

    :host {
        position: relative;
        width: 100%;
        height: calc(${designTokens['space-design-unit'].token} * 1px);
    }

    .progressBar {
        position: absolute;
        background: linear-gradient(
          to right,
            transparent,
            ${accentFillRest},
            transparent
          );
        height: 100%;
        right:100%;
        left:0;
        width:0;
        animation: progressBarKey 2s linear infinite;
    }

    @keyframes progressBarKey {
        0% {
          left:0%;
          right:100%;
          width:0%;
        }
        10% {
          left:0%;
          right:75%;
          width:25%;
        }
        90% {
          right:0%;
          left:75%;
          width:25%;
        }
        100% {
          left:100%;
          right:0%;
          width:0%;
        }
      }
`;
