import { sideBarTemplate } from './side-bar.template';
import { sideBarStyles } from './side-bar.styles';
import { FoundationElement } from '@microsoft/fast-foundation';

/**
 * @element natgen-side-bar
 */
export class NatGenSideBar extends FoundationElement { }

export const natGenSideBar = NatGenSideBar.compose({
    baseName: 'side-bar',
    template: sideBarTemplate,
    styles: sideBarStyles
});
