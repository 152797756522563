import { attr } from '@microsoft/fast-element';
import { Combobox, ComboboxAutocomplete, ComboboxOptions, comboboxTemplate } from '@microsoft/fast-foundation';
import { angleDown } from '../../styles/icons';
import { NatGenTextFieldAppearance } from '../text-field/text-field';
import { comboboxStyles } from './combobox.styles';

/**
 * @element natgen-combobox
 */
export class NatGenCombobox extends Combobox {
    @attr({ attribute: 'autocomplete', mode: 'fromView' })
    autocomplete: ComboboxAutocomplete | undefined = 'list';

    /**
     * The appearance of the element.
     *
     * @public
     * @remarks
     * HTML Attribute: appearance
     */
    @attr
    public appearance: NatGenTextFieldAppearance;

    public appearanceChanged(
        oldValue: NatGenTextFieldAppearance,
        newValue: NatGenTextFieldAppearance
    ): void {
        if (oldValue !== newValue) {
            this.classList.add(newValue);
            this.classList.remove(oldValue);
        }
    }
}

export const natGenCombobox = NatGenCombobox.compose<ComboboxOptions>({
    baseName: 'combobox',
    template: comboboxTemplate,
    styles: comboboxStyles,
    indicator: angleDown
});
