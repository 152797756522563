import { ListboxOption, listboxOptionTemplate } from '@microsoft/fast-foundation';
import { optionStyles } from './option.styles';

/**
 * @element natgen-option
 */
export class NatGenOption extends ListboxOption {

}

export const natGenOption = NatGenOption.compose({
    baseName: 'option',
    template: listboxOptionTemplate,
    styles: optionStyles
});
