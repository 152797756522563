import { attr } from '@microsoft/fast-element';
import { Anchor, anchorTemplate } from '@microsoft/fast-foundation';
import { ButtonAppearance } from '../../styles/patterns/button';
import { anchorStyles } from './anchor.styles';

/**
 * @element natgen-anchor
 */
export class NatGenAnchor extends Anchor {
    /**
     * The appearance the anchor should have.
     *
     * @remarks
     * HTML Attribute: appearance
     */
    @attr
    public appearance: ButtonAppearance;

    public connectedCallback() {
        super.connectedCallback();
        if (!this.appearance) {
            this.appearance = 'hypertext';
        }
    }

    /**
     * Applies 'icon-only' class when there is only an SVG in the default slot
     */
    public defaultSlottedContentChanged(oldValue, newValue): void {
        const slottedElements = this.defaultSlottedContent.filter(
            x => x.nodeType === Node.ELEMENT_NODE
        );
        if (slottedElements.length === 1 && slottedElements[0] instanceof SVGElement) {
            this.control.classList.add('icon-only');
        } else {
            this.control.classList.remove('icon-only');
        }
    }
}

export const natGenAnchor = NatGenAnchor.compose({
    baseName: 'anchor',
    baseClass: Anchor,
    template: anchorTemplate,
    styles: anchorStyles,
    shadowOptions: {
        delegatesFocus: true,
    },
});
