import { html, slotted, ViewTemplate } from '@microsoft/fast-element';
import { endSlotTemplate, FoundationElementTemplate, startSlotTemplate } from '@microsoft/fast-foundation';
import { NatGenRadioCard, RadioCardOptions } from './radio-card';

export const radioCardTemplate: FoundationElementTemplate<ViewTemplate<NatGenRadioCard>, RadioCardOptions> = (
    context,
    definition
) => html<NatGenRadioCard>`
    <template
        role="radio"
        class="${x => (x.checked ? "checked" : "")} ${x =>
            x.readOnly ? "readonly" : ""}"
        aria-checked="${x => x.checked}"
        aria-required="${x => x.required}"
        aria-disabled="${x => x.disabled}"
        aria-readonly="${x => x.readOnly}"
        @keypress="${(x, c) => x.keypressHandler(c.event as KeyboardEvent)}"
        @click="${(x, c) => x.clickHandler(c.event as MouseEvent)}"
    >
        ${startSlotTemplate(context, definition)}
        <label
            part="label"
            class="${x =>
                x.defaultSlottedNodes && x.defaultSlottedNodes.length
                    ? "label"
                    : "label label__hidden"}"
        >
            <slot ${slotted("defaultSlottedNodes")}></slot>
        </label>
        ${endSlotTemplate(context, definition)}

        <div part="control" class="control">
            <slot name="checked-indicator">
                ${definition.checkedIndicator}
            </slot>
        </div>
    </template>
`;
