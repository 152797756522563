import { css, ElementStyles } from "@microsoft/fast-element"
import { ElementDefinitionContext, FoundationElementDefinition, RadioOptions, StartEndOptions } from "@microsoft/fast-foundation"
import { accentForegroundRest, designTokens, neutralStrokeActive, neutralStrokeRest } from "../../design-tokens";
import { heightNumber, paddingNumber } from "../size";

export const selectableCardStyles: (
    context: ElementDefinitionContext,
    definition: FoundationElementDefinition
) => ElementStyles = (
    context: ElementDefinitionContext,
    definition: FoundationElementDefinition
) => css`
    :host {
        display: inline-flex;
        align-items: center;

        padding: calc(${designTokens['space-design-unit'].token} * 1px) calc(${paddingNumber} * 1px);
        min-height: calc(${heightNumber} * 1px);

        cursor: pointer;

        --card-width: auto;

        border-width: calc(${designTokens['space-stroke-width'].token} * 1px) !important;
        border-color: ${neutralStrokeRest} !important;
    }

    :host(:hover) {
        border-color: ${accentForegroundRest} !important;
    }

    :host(:active) {
        border-color: ${neutralStrokeActive} !important;
    }

    :host([floating-indicator]) {
        margin-top: calc(${designTokens['space-design-unit'].token} * 2px);
        margin-bottom: calc(${designTokens['space-design-unit'].token} * 2px);
    }

    :host([floating-indicator]) .control {
        position: absolute;
    }

    :host([floating-indicator]:not(.checked)) .control {
        display: none;
    }

    :host([floating-indicator*="top"]) .control {
        top: calc(var(--control-size) / -2);
    }

    :host([floating-indicator*="bottom"]) .control {
        bottom: calc(var(--control-size) / -2);
    }

    :host([floating-indicator*="left"]) .control {
        left: calc(var(--control-size) / -2);
    }

    :host([floating-indicator*="right"]) .control {
        right: calc(var(--control-size) / -2);
    }

    .control {
        margin-left: auto;
    }

    .label {
        padding: 0;
    } 

    .start {
        margin-inline-end: calc(${designTokens['space-design-unit'].token} * 2px);
    }

    .end {
        margin-inline-start: calc(${designTokens['space-design-unit'].token} * 2px);
    }
`;