import { loaderTemplate } from './loader.template';
import { loaderStyles } from './loader.styles';
import { FoundationElement } from '@microsoft/fast-foundation';

/**
 * @element natgen-loader
 */
export class NatGenLoader extends FoundationElement { }

export const natGenLoader = NatGenLoader.compose({
    baseName: 'loader',
    template: loaderTemplate,
    styles: loaderStyles
});
