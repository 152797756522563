import { css } from '@microsoft/fast-element';
import { designTokens } from '../design-tokens';

export const typeRampStyles = css`
    

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        font-weight: 700;
    }

    h1 {
        font-size: ${designTokens['typography-type-ramp-plus-6-font-size'].token};
        line-height: ${designTokens['typography-type-ramp-plus-6-line-height'].token};
    }

    h2 {
        font-size: ${designTokens['typography-type-ramp-plus-5-font-size'].token};
        line-height: ${designTokens['typography-type-ramp-plus-5-line-height'].token};
    }

    h3 {
        font-size: ${designTokens['typography-type-ramp-plus-4-font-size'].token};
        line-height: ${designTokens['typography-type-ramp-plus-4-line-height'].token};
    }

    h4 {
        font-size: ${designTokens['typography-type-ramp-plus-3-font-size'].token};
        line-height: ${designTokens['typography-type-ramp-plus-3-line-height'].token};
    }

    h5 {
        font-size: ${designTokens['typography-type-ramp-plus-2-font-size'].token};
        line-height: ${designTokens['typography-type-ramp-plus-2-line-height'].token};
    }

    h6 {
        font-size: ${designTokens['typography-type-ramp-plus-1-font-size'].token};
        line-height: ${designTokens['typography-type-ramp-plus-1-line-height'].token};
    }
`;
