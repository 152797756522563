import { css, ElementStyles } from '@microsoft/fast-element';
import { FoundationElementTemplate } from '@microsoft/fast-foundation';
import { designTokens } from '../../design-tokens';
import { elevationRule } from '../../styles/elevation';

export const paletteStyles: FoundationElementTemplate<ElementStyles> = (
    context,
    definition
) => css`
    .swatch {
        --elevation: 2;
        display: inline-block;
        width: calc(${designTokens['space-design-unit'].token} * 3px);
        height: calc(${designTokens['space-design-unit'].token} * 3px);
        ${elevationRule}
    }

    .target {
        font-weight: bold;
    }
`;
