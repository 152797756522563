import { checkboxStyles } from './checkbox.styles';
import { Checkbox, CheckboxOptions, checkboxTemplate } from '@microsoft/fast-foundation';
import { checkIcon, minusIcon } from '../../styles/icons';

/**
 * @element natgen-checkbox
 */
export class NatGenCheckbox extends Checkbox { }

export const natGenCheckbox = NatGenCheckbox.compose<CheckboxOptions>({
    baseName: 'checkbox',
    template: checkboxTemplate,
    styles: checkboxStyles,
    checkedIndicator: checkIcon,
    indeterminateIndicator: minusIcon
});
