import { css } from "@microsoft/fast-element";
import { forcedColorsStylesheetBehavior } from "@microsoft/fast-foundation";
import { SystemColors } from "@microsoft/fast-web-utilities";
import { designTokens, fillColor, neutralForegroundRest } from "../../design-tokens";

export const backgroundStyles = css`
    :host {
        background-color: ${fillColor};
        color: ${neutralForegroundRest};

        font-family: ${designTokens['typography-font-family'].token};
        font-size: ${designTokens['typography-type-ramp-base-font-size'].token};
        line-height: ${designTokens['typography-type-ramp-base-line-height'].token};
    }
`.withBehaviors(
    forcedColorsStylesheetBehavior(
        css`
            :host {
                background-color: ${SystemColors.ButtonFace};
                box-shadow: 0 0 0 1px ${SystemColors.CanvasText};
                color: ${SystemColors.ButtonText};
            }
        `
));
