import { attr, Constructable, ElementStyles } from '@microsoft/fast-element';
import { DI, FoundationElement, inject, PropertyStyleSheetBehavior } from '@microsoft/fast-foundation';
import { designTokens } from '../design-tokens';

export const mediaSizes = ['extra-small', 'small', 'medium', 'large', 'extra-large'] as const;
export type MediaSize = typeof mediaSizes[number];

export const mediaSizeObserver = DI.createInterface<ResizeObserver>(
    x => x.instance(new ResizeObserver((entries) => {
        for(const entry of entries) {
            if(entry.borderBoxSize) {
                var width = entry.borderBoxSize[0].inlineSize;

                if(width > designTokens['space-breakpoint-extra-large'].getValueFor(entry.target as HTMLElement))
                    entry.target.setAttribute('media-size', 'extra-large');
                else if (width > designTokens['space-breakpoint-large'].getValueFor(entry.target as HTMLElement))
                    entry.target.setAttribute('media-size', 'large');
                else if (width > designTokens['space-breakpoint-medium'].getValueFor(entry.target as HTMLElement))
                    entry.target.setAttribute('media-size', 'medium');
                else if (width > designTokens['space-breakpoint-small'].getValueFor(entry.target as HTMLElement))
                    entry.target.setAttribute('media-size', 'small');
                else
                    entry.target.setAttribute('media-size', 'extra-small');
            }
        }
    }))
);

export function mediaSizeBehavior(value: string, styles: ElementStyles) {
    return new PropertyStyleSheetBehavior('mediaSize', value, styles);
}

export function MediaSizeMixin<TBase extends Constructable<FoundationElement>>(Base: TBase) {
    class mediaSizeMixin extends Base {
        @inject(mediaSizeObserver)
        protected mediaSizeObserver!: ResizeObserver;

        /** A readonly attribute which reflects the closest breakpoint size of this container. */
        @attr({ attribute: 'media-size' })
        mediaSize: MediaSize;

        connectedCallback() {
            super.connectedCallback();
            this.mediaSizeObserver.observe(this);
        }

        disconnectedCallback() {
            super.disconnectedCallback();
            this.mediaSizeObserver.unobserve(this);
        }
    }

    return mediaSizeMixin;
}
