import { css } from '@microsoft/fast-element';
import { display, ElementDefinitionContext, focusVisible, FoundationElementDefinition, OverrideFoundationElementDefinition } from '@microsoft/fast-foundation';
import { accentFillActive, accentFillFocus, accentFillHover, accentFillRest, accentForegroundActive, accentForegroundHover, accentForegroundRest, focusStrokeInner, focusStrokeOuter, foregroundOnAccentActive, foregroundOnAccentHover, foregroundOnAccentRest, neutralFillActive, neutralFillFocus, neutralFillHover, neutralFillRest, neutralForegroundActive, neutralForegroundFocus, neutralForegroundHover, neutralForegroundRest, neutralStealthFillActive, neutralStealthFillHover, neutralStealthFillRest, designTokens, neutralStrokeRest, neutralStrokeHover, neutralStrokeActive } from '../../design-tokens';
import { heightNumber } from '../size';

// TODO: Support high contrast? https://www.fast.design/docs/design-systems/high-contrast/#styling-components-using-forced-colors

export type ButtonAppearance =
    'neutral'
    | 'accent'
    | 'lightweight'
    | 'outline'
    | 'outline-accent'
    | 'stealth'
    | 'hypertext'
    | 'hypertext-accent'

export const BaseButtonStyles = (
    context: ElementDefinitionContext,
    definition: FoundationElementDefinition,
    interactivitySelector: string = '',
    nonInteractivitySelector: string = ''
) => css`
    ${display('inline-flex')} :host {
        justify-content: center;
        align-items: center;

        border-style: solid;
        border-color: transparent !important;
        /* TODO: Remove important after tailwind bug is resolved: https://github.com/tailwindlabs/tailwindcss/discussions/2261 */
        border-width: calc(${designTokens['space-stroke-width'].token} * 1px) !important;
        border-radius: calc(${designTokens['space-button-corner-radius'].token} * 1px);
        height: calc(${heightNumber} * 1px);
        min-width: calc(${heightNumber} * 0.5px);

        background-color: ${neutralFillRest};
        color: ${neutralForegroundRest};
        fill: currentColor;

        cursor: pointer;
        outline: none;

        -webkit-appearance: none !important;
    }

    :host .control {
        display: inline-flex;

        flex-grow: 1;
        justify-content: inherit;
        align-items: inherit;

        box-sizing: border-box;

        border: none;
        border-radius: inherit;

        padding: 0 calc((10 + (${designTokens['space-design-unit'].token} * 2 * ${designTokens['space-density'].token})) * 1px);
        height: inherit;

        background: transparent;
        color: inherit;
        fill: inherit;

        font-family: inherit;
        font-size: inherit;
        white-space: nowrap;

        outline: none;
        text-decoration: none;
        cursor: inherit;
    }

    .control,
    .end,
    .start {
        font: inherit;
    }
    .control.icon-only {
        padding: 0;
        line-height: 0;
    }
    :host(${interactivitySelector}:hover) {
        background: ${neutralFillHover};
        color: ${neutralForegroundHover};
    }
    :host(${interactivitySelector}:active) {
        background: ${neutralFillActive};
        color: ${neutralForegroundActive};
    }
    :host(:${focusVisible}) {
        background: ${neutralFillFocus};
        border-color: ${focusStrokeOuter} ;
        box-shadow: 0 0 0 calc(${designTokens['space-focus-stroke-width'].token} * 1px) ${focusStrokeOuter} inset ;
        color: ${neutralForegroundHover};
    }
    .control::-moz-focus-inner {
        border: 0;
    }
    .content {
        pointer-events: none;
    }
    .start,
    .end {
        display: flex;
        pointer-events: none;
    }
    .start {
        margin-inline-end: calc(${designTokens['space-design-unit'].token} * 2px);
    }
    .end {
        margin-inline-start: calc(${designTokens['space-design-unit'].token} * 2px);
    }
`;

export const AccentButtonStyles = (
    context: ElementDefinitionContext,
    definition: OverrideFoundationElementDefinition<any>,
    interactivitySelector: string = "",
    nonInteractivitySelector: string = ""
) =>
    css`
        :host {
            background: ${accentFillRest};
            color: ${foregroundOnAccentRest};
        }
        :host(${interactivitySelector}:hover) {
            background: ${accentFillHover};
            color: ${foregroundOnAccentHover};
        }
        :host(${interactivitySelector}:active) {
            background: ${accentFillActive};
            color: ${foregroundOnAccentActive};
        }
        :host(:${focusVisible}) {
            background: ${accentFillFocus};
            box-shadow: 0 0 0 calc((${designTokens['space-focus-stroke-width'].token} - ${designTokens['space-stroke-width'].token}) * 1px) ${focusStrokeOuter} inset,
              0 0 0 calc(((${designTokens['space-focus-stroke-width'].token} * 2) - ${designTokens['space-stroke-width'].token}) * 1px) ${focusStrokeInner} inset ;
            color: ${foregroundOnAccentHover};
        }
    `;

export const HypertextStyles = (
    context: ElementDefinitionContext,
    definition: OverrideFoundationElementDefinition<any>,
    interactivitySelector: string = "",
    nonInteractivitySelector: string = ""
) =>
    css`
        :host {
            height: auto;
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
            min-width: auto;
            background: transparent;
            /* needs important because of tailwind */
            border: none !important;
            box-shadow: none;
        }

        :host .control {
            display: inline;
            padding: 0;
            line-height: 1;
            text-decoration: underline;
            text-decoration-thickness: 1px;
        }
        :host(${interactivitySelector}),
        :host(${interactivitySelector}:hover),
        :host(${interactivitySelector}:active) {
            background: transparent;
        }
        :host(:${focusVisible}) {
            box-shadow: none;
        }
        :host .control${interactivitySelector}:active {
            text-decoration: none;
        }
        :host .control:${focusVisible} {
            text-decoration: underline;
            text-decoration-thickness: 3px;
        }
    `;

export const HypertextAccentStyles = (
    context: ElementDefinitionContext,
    definition: OverrideFoundationElementDefinition<any>,
    interactivitySelector: string = "",
    nonInteractivitySelector: string = ""
) =>
    css`
        ${HypertextStyles(context, definition, interactivitySelector, nonInteractivitySelector)}

        :host .control${interactivitySelector} {
            color: ${accentForegroundRest}
        }

        :host .control${interactivitySelector}:hover {
            color: ${accentForegroundHover}
        }
        :host .control${interactivitySelector}:active {
            color: ${accentForegroundActive}
        }
    `;

export const LightweightButtonStyles = (
    context: ElementDefinitionContext,
    definition: OverrideFoundationElementDefinition<any>,
    interactivitySelector: string = "",
    nonInteractivitySelector: string = ""
) =>
    css`
        :host {
            background: transparent;
            border: none !important;
            border-radius: 0;
            height: initial;
            min-width: auto;
        }

        :host .control {
            padding: 0;
        }
        :host .content {
            position: relative;
        }
        :host(${interactivitySelector}:hover),
        :host(${interactivitySelector}:active) {
            background: transparent;
        }
        :host(:${focusVisible}) {
            border-color: transparent;
            box-shadow: none ;
        }
        :host .content::before {
            content: "";
            display: block;
            height: calc(${designTokens['space-stroke-width'].token} * 1px);
            position: absolute;
            top: calc(1em + 3px);
            width: 100%;
            background: transparent;
        }
        :host(${interactivitySelector}:hover) .content::before {
            background: ${neutralForegroundHover};
        }
        :host(${interactivitySelector}:active) .content::before {
            background: transparent;
        }
        :host(${interactivitySelector}:${focusVisible}) .content::before {
            background: ${neutralForegroundFocus};
            height: calc(${designTokens['space-stroke-width'].token} * 2px);
        }
    `;

export const OutlineButtonStyles = (
    context: ElementDefinitionContext,
    definition: OverrideFoundationElementDefinition<any>,
    interactivitySelector: string = "",
    nonInteractivitySelector: string = ""
) =>
    css`
        :host {
            background: transparent;
            border-width: calc(${designTokens['space-stroke-width'].token} * 1px) !important;
            border-color: ${neutralStrokeRest} !important;
        }
        :host(${interactivitySelector}:hover) {
            background: transparent;
            border-color: ${neutralStrokeHover} !important;
        }
        :host(${interactivitySelector}:active) {
            background: ${neutralFillActive};
            border-color: ${neutralStrokeActive} !important;
        }
    `;

export const OutlineAccentButtonStyles = (
    context: ElementDefinitionContext,
    definition: OverrideFoundationElementDefinition<any>,
    interactivitySelector: string = "",
    nonInteractivitySelector: string = ""
) =>
    css`
        :host {
            background: transparent;
            border-width: calc(${designTokens['space-stroke-width'].token} * 1px) !important;
            border-color: ${accentFillRest} !important;
        }
        :host(${interactivitySelector}:hover) {
            background: transparent;
            border-color: ${accentFillHover} !important;
        }
        :host(${interactivitySelector}:active) {
            background: ${accentFillActive};
            border-color: ${accentFillActive} !important;
            color: ${foregroundOnAccentActive}
        }
    `;

/**
 * @internal
 */
export const StealthButtonStyles = (
    context: ElementDefinitionContext,
    definition: OverrideFoundationElementDefinition<any>,
    interactivitySelector: string = "",
    nonInteractivitySelector: string = ""
) =>
    css`
        :host {
            background: ${neutralStealthFillRest};
        }
        :host(${interactivitySelector}:hover),
        :host(${interactivitySelector}:${focusVisible}) {
            background: ${neutralStealthFillHover};
        }
        :host(${interactivitySelector}:active) {
            background: ${neutralStealthFillActive};
        }
    `;
