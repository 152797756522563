import { html, observable } from "@microsoft/fast-element";
import { FoundationElement } from "@microsoft/fast-foundation";
import { NatGenPortal } from "./portal";

/**
 * @element natgen-portal-outlet
 */
export class NatGenPortalOutlet extends FoundationElement {
    @observable
    attachedPortals: NatGenPortal[] = [];

    onPortalUpdate(): void {
        this.textContent = '';
        this.attachedPortals.forEach(p => p.childNodes.forEach(c => this.append(c.cloneNode(true))));
    }

    attach(portal : NatGenPortal) {
        this.attachedPortals.push(portal);
        this.onPortalUpdate();
    }

    detach(portal: NatGenPortal) {
        try {
            this.attachedPortals.splice(this.attachedPortals.indexOf(portal), 1);
        } catch { }
    }
}

export const natGenPortalOutlet = NatGenPortalOutlet.compose({
    baseName: 'portal-outlet',
    template: html<NatGenPortalOutlet>`<slot></slot>`
});