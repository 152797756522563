import { attr, css, html } from "@microsoft/fast-element";
import { FoundationElement } from "@microsoft/fast-foundation";
import { designTokens } from "../../design-tokens";

/**
 * @element natgen-token-value
 */
export class NatGenTokenValue extends FoundationElement {
    @attr
    public name: string;
}

export const natGenTokenValue = NatGenTokenValue.compose({
    baseName: 'token-value',
    styles: css`
        
    `,
    template: html<NatGenTokenValue>`
        <template>
            ${x => designTokens[x.name]?.getValueFor(x)}
        </template>
    `
});