import { css, ElementStyles } from '@microsoft/fast-element';
import { disabledCursor, display, focusVisible, forcedColorsStylesheetBehavior, FoundationElementTemplate, ComboboxOptions } from '@microsoft/fast-foundation';
import { SystemColors } from '@microsoft/fast-web-utilities';
import { accentFillHover, neutralFillActive, neutralFillHover, neutralFillRest, neutralForegroundActive, neutralForegroundRest, neutralInputFillHover, neutralInputFillRest, neutralLayer1, neutralStealthFillActive, neutralStealthFillHover, neutralStealthFillRest, neutralStrokeFocus, neutralStrokeRest, designTokens } from '../../design-tokens';
import { appearanceBehavior } from '../../styles/behaviors';
import { elevationRule } from '../../styles/elevation';
import { heightNumber, paddingNumber } from '../../styles/size';

export const comboboxFilledStyles: FoundationElementTemplate<ElementStyles, ComboboxOptions> = (
    context,
    definition
) => css`
    ${display("inline-flex")} :host .control {
        background: ${neutralFillRest};
    }

    :host(:not([disabled]):hover) .control {
        background: ${neutralFillHover};
    }

    :host(:not([disabled]):active) .control {
        background: ${neutralFillActive};
    }
`;

export const comboboxStealthStyles: FoundationElementTemplate<ElementStyles, ComboboxOptions> = (
    context,
    definition
) => css`
        :host .control {
            background: ${neutralStealthFillRest};
            border-color: transparent;
            box-shadow: none;
        }

        :host(:not([disabled]):hover) .control {
            background: ${neutralStealthFillHover};
            border-color: transparent;
            box-shadow: none;
        }

        :host(:not([disabled]):active) .control {
            background: ${neutralStealthFillActive};
            border-color: transparent;
            box-shadow: none;
        }
    `;

export const comboboxLightweightStyles: FoundationElementTemplate<ElementStyles, ComboboxOptions> = (
    context,
    definition
) => css`
        :host .control {
            background: transparent;
            border-color: transparent;
            box-shadow: none;
        }

        :host(:not([disabled]):hover) .control {
            background: ${neutralStealthFillHover};
            border-color: transparent;
            box-shadow: none;
        }

        :host(:not([disabled]):active) .control {
            background: ${neutralStealthFillActive};
            border-color: transparent;
            box-shadow: none;
        }
    `;

export const comboboxStyles: FoundationElementTemplate<ElementStyles, ComboboxOptions> = (
    context,
    definition
) => css`
    ${display("inline-flex")} :host {
        --elevation: 14;
        --listbox-max-height: 6;
        position: relative;
        box-sizing: border-box;

        font-family: ${designTokens['typography-font-family'].token};
        fill: currentcolor;

        user-select: none;
        outline: none;
    }

    :host .listbox {
        ${elevationRule}
        background: ${neutralLayer1};
        border: calc(${designTokens['space-stroke-width'].token} * 1px) solid ${neutralStrokeRest};
        border-radius: calc(${designTokens['space-input-corner-radius'].token} * 1px);
        box-sizing: border-box;
        display: inline-flex;
        flex-direction: column;
        left: 0;
        max-height: calc((var(--listbox-max-height) * ${heightNumber} + ${designTokens['space-stroke-width'].token}) * 1px);
        padding: 0;
        overflow-y: auto;
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    :host .listbox[hidden] {
        display: none;
    }

    :host .control {
        border: calc(${designTokens['space-stroke-width'].token} * 1px) solid ${neutralStrokeRest};
        border-radius: calc(${designTokens['space-input-corner-radius'].token} * 1px);
        height: calc(${heightNumber} * 1px);
        vertical-align: top;
        background: ${neutralInputFillRest};
        color: ${neutralForegroundRest};
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        padding-left: calc(${paddingNumber} * 1px);
        width: 100%;
    }

    :host(:not([disabled]):hover) .control {
        background: ${neutralInputFillHover};
        border-color: ${accentFillHover};
    }

    :host(:${focusVisible}) .control {
        border-color: ${neutralStrokeFocus};
        box-shadow: 0 0 0 calc(${designTokens['space-focus-stroke-width'].token} * 1px) ${neutralStrokeFocus};
    }

    :host([disabled]) {
        cursor: ${disabledCursor};
        opacity: ${designTokens['color-disabled-opacity'].token};
    }

    :host([disabled]) .control {
        cursor: ${disabledCursor};
        user-select: none;
    }

    :host([open]) .control {
        color: ${neutralForegroundActive};
    }

    :host([open][position="above"]) .listbox {
        bottom: calc((${heightNumber} + ${designTokens['space-design-unit'].token}) * 1px);
    }

    :host([open][position="below"]) .listbox {
        top: calc((${heightNumber} + ${designTokens['space-design-unit'].token}) * 1px);
    }

    .selected-value {
        -webkit-appearance: none;
        background: transparent;
        border: none;
        color: inherit;
        font-size: ${designTokens['typography-type-ramp-base-font-size'].token};
        line-height: ${designTokens['typography-type-ramp-base-line-height'].token};
        height: calc(100% - (${designTokens['space-stroke-width'].token} * 1px));
        margin: auto 0;
        width: 100%;
    }

    .selected-value:hover,
    .selected-value:${focusVisible},
    .selected-value:disabled,
    .selected-value:active {
        outline: none;
    }

    .indicator {
        flex: 0 0 auto;
        margin-inline-start: 1em;
    }

    slot[name="listbox"] {
        display: none;
        width: 100%;
    }

    :host([open]) slot[name="listbox"] {
        display: flex;
        position: absolute;
        ${elevationRule}
    }

    .end {
        margin-inline-start: auto;
    }

    .start,
    .end,
    .indicator,
    ::slotted(svg) {
        display: flex;
        height: 1em;
        min-height: calc(${designTokens['space-design-unit'].token} * 4px);
        min-width: calc(${designTokens['space-design-unit'].token} * 4px);
        width: 1em;
    }

    ::slotted([role="option"]) {
        flex: 0 0 auto;
    }
`.withBehaviors(
    appearanceBehavior("filled", comboboxFilledStyles(context, definition)),
    appearanceBehavior("stealth", comboboxStealthStyles(context, definition)),
    appearanceBehavior("lightweight", comboboxLightweightStyles(context, definition)),
    forcedColorsStylesheetBehavior(
        css`
            :host([disabled]) {
                border-color: ${SystemColors.GrayText};
                color: ${SystemColors.GrayText};
                opacity: 1;
            }

            :host(:not([disabled]):hover) {
                background: ${SystemColors.ButtonFace};
                border-color: ${SystemColors.Highlight};
            }

            :host(:${focusVisible}) {
                forced-color-adjust: none;
                background: ${SystemColors.ButtonFace};
                border-color: ${SystemColors.Highlight};
                box-shadow: 0 0 0 calc(${designTokens['space-focus-stroke-width'].token} * 1px) inset ${SystemColors.Highlight};
                color: ${SystemColors.ButtonText};
                fill: currentcolor;
            }

            :host([open]) .listbox {
                background: ${SystemColors.ButtonFace};
                border: 1px solid ${SystemColors.ButtonText};
            }

            :host(:${focusVisible}) ::slotted([role="option"][aria-selected="true"]:not([disabled])) {
                background: ${SystemColors.Highlight};
                border-color: ${SystemColors.ButtonText};
                box-shadow: 0 0 0 calc(${designTokens['space-focus-stroke-width'].token} * 1px) inset ${SystemColors.HighlightText};
                color: ${SystemColors.HighlightText};
            }

            ::slotted([role="option"]:not([aria-selected="true"]):not([disabled]):hover) {
                color: ${SystemColors.ButtonText};
                background: ${SystemColors.ButtonFace};
                border-color: ${SystemColors.Highlight};
                box-shadow: none;
            }
        `
    )
);
