import { css, ElementStyles } from '@microsoft/fast-element';
import { disabledCursor, display, focusVisible, forcedColorsStylesheetBehavior, FoundationElementTemplate, ListboxOptionOptions } from '@microsoft/fast-foundation';
import { SystemColors } from '@microsoft/fast-web-utilities';
import { accentFillFocus, accentFillHover, focusStrokeOuter, foregroundOnAccentFocus, foregroundOnAccentHover, neutralFillHover, neutralForegroundHover, neutralForegroundRest, neutralLayer1, neutralLayer3, neutralStealthFillHover, neutralStealthFillRest, neutralStrokeRest, designTokens } from '../../design-tokens';
import { heightNumber } from '../../styles/size';

export const optionStyles: FoundationElementTemplate<ElementStyles, ListboxOptionOptions> = (
    context,
    definition
) => css`
        ${display("inline-flex")} :host {
            position: relative;
            font-family: ${designTokens['typography-font-family'].token};
            background: ${neutralStealthFillRest};
            border: calc(${designTokens['space-stroke-width'].token} * 1px) solid ${neutralLayer1};
            box-sizing: border-box;
            color: ${neutralForegroundRest};
            cursor: pointer;
            flex: 0 0 auto;
            fill: currentcolor;
            font-size: ${designTokens['typography-type-ramp-base-font-size'].token};
            height: calc(${heightNumber} * 1px);
            line-height: ${designTokens['typography-type-ramp-base-line-height'].token};
            outline: none;
            overflow: hidden;
            align-items: center;
            padding: 0 1ch;
            user-select: none;
            white-space: nowrap;
        }

        :host(:not([disabled]):hover),
        :host(:not([disabled]):not([aria-selected="true"]):hover) {
            background: ${neutralStealthFillHover};
            color: ${neutralForegroundHover};
        }

        :host(:not([disabled]):active),
        :host(:not([disabled]):not([aria-selected='true']):active),
        :host([aria-selected="true"]),
        :host(:not([disabled])[aria-selected="true"]:hover),
        :host(:not([disabled])[aria-selected="true"]:active) {
            background: ${accentFillHover};
            border-color: ${accentFillHover};
            color: ${foregroundOnAccentHover};
        }

        :host(:${focusVisible}) {
            border-color: ${focusStrokeOuter};
        }

        :host([disabled]) {
            cursor: ${disabledCursor};
            opacity: ${designTokens['color-disabled-opacity'].token};
        }

        .content {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .start,
        .end,
        ::slotted(svg) {
            display: flex;
        }

        ::slotted([slot="end"]) {
            margin-inline-start: 1ch;
        }

        ::slotted([slot="start"]) {
            margin-inline-end: 1ch;
        }

        :host([aria-checked="true"][aria-selected="false"]) {
            border-color: ${neutralStrokeRest};
            background: ${neutralLayer3};
            color: ${neutralForegroundRest};
        }

        :host([aria-checked="true"][aria-selected="false"]:not([disabled]):hover) {
            background: ${neutralFillHover};
        }

        :host([aria-checked="true"][aria-selected="true"]) {
            border-color: ${focusStrokeOuter};
            background: ${accentFillFocus};
            color: ${foregroundOnAccentFocus};
        }

        :host([aria-checked="true"][aria-selected="true"]:hover) {
            background: ${accentFillHover};
            color: ${foregroundOnAccentHover};
        }
  `.withBehaviors(
        forcedColorsStylesheetBehavior(
            css`
                :host {
                    background: ${SystemColors.ButtonFace};
                    border-color: transparent;
                    color: ${SystemColors.ButtonText};
                    forced-color-adjust: none;
                }

                :host(:not([disabled])[aria-selected="true"]:hover),
                :host(:not([disabled])[aria-selected="true"]:active),
                :host(:not([disabled]):not([aria-selected="true"]):hover),
                :host(:not([disabled]):not([aria-selected="true"]):active),
                :host([aria-selected="true"]) {
                    background: ${SystemColors.Highlight};
                    color: ${SystemColors.HighlightText};
                }

                :host([disabled]),
                :host([disabled][aria-selected="false"]:hover) {
                    background: ${SystemColors.Canvas};
                    color: ${SystemColors.GrayText};
                    fill: currentcolor;
                    opacity: 1;
                }

                :host([aria-checked="true"][aria-selected="false"]) {
                    background: ${SystemColors.ButtonFace};
                    color: ${SystemColors.ButtonText};
                    border-color: ${SystemColors.ButtonText};
                }

                :host([aria-checked="true"][aria-selected="true"]),
                :host([aria-checked="true"][aria-selected="true"]:hover) {
                    background: ${SystemColors.Highlight};
                    color: ${SystemColors.HighlightText};
                    border-color: ${SystemColors.ButtonText};
                }
            `
        )
    );
