import { html, ref, slotted, ViewTemplate, when } from '@microsoft/fast-element';
import { endSlotTemplate, FoundationElementTemplate, startSlotTemplate, TextFieldOptions } from '@microsoft/fast-foundation';
import { NatGenTextField } from './text-field';

export const textFieldTemplate: FoundationElementTemplate<ViewTemplate<NatGenTextField>, TextFieldOptions> = (
    context,
    definition
) => html<NatGenTextField>`
    <template class="${x => (x.readOnly ? "readonly" : "")} ${x => x.hasLabel() ? "label__visible": "label__hidden"}">
        <div class="root" part="root">
            ${startSlotTemplate(context, definition)}

            <span class="control-container">
                <input
                    class="control"
                    part="control"
                    @input="${x => x.handleTextInput()}"
                    @change="${x => x.handleChange()}"
                    ?autofocus="${x => x.autofocus}"
                    ?disabled="${x => x.disabled}"
                    list="${x => x.list}"
                    maxlength="${x => x.maxlength}"
                    minlength="${x => x.minlength}"
                    max="${x => x.max}"
                    min="${x => x.min}"
                    pattern="${x => x.pattern}"
                    placeholder="${x => x.placeholder || " "}"
                    inputmode="${x => x.inputMode}"
                    ?readonly="${x => x.readOnly}"
                    ?required="${x => x.required}"
                    size="${x => x.size}"
                    ?spellcheck="${x => x.spellcheck}"
                    :value="${x => x.value}"
                    type="${x => x.type}"
                    aria-atomic="${x => x.ariaAtomic}"
                    aria-busy="${x => x.ariaBusy}"
                    aria-controls="${x => x.ariaControls}"
                    aria-current="${x => x.ariaCurrent}"
                    aria-describedBy="${x => x.ariaDescribedby}"
                    aria-details="${x => x.ariaDetails}"
                    aria-disabled="${x => x.ariaDisabled}"
                    aria-errormessage="${x => x.ariaErrormessage}"
                    aria-flowto="${x => x.ariaFlowto}"
                    aria-haspopup="${x => x.ariaHaspopup}"
                    aria-hidden="${x => x.ariaHidden}"
                    aria-invalid="${x => x.ariaInvalid}"
                    aria-keyshortcuts="${x => x.ariaKeyshortcuts}"
                    aria-label="${x => x.ariaLabel}"
                    aria-labelledby="${x => x.ariaLabelledby}"
                    aria-live="${x => x.ariaLive}"
                    aria-owns="${x => x.ariaOwns}"
                    aria-relevant="${x => x.ariaRelevant}"
                    aria-roledescription="${x => x.ariaRoledescription}"
                    ${ref("control")}
                />

                <label
                    part="label"
                    for="control"
                    class="label"
                >
                    <slot ${slotted("defaultSlottedNodes")}></slot>
                </label>
            </span>

            ${when(x => x.isPasswordType, html`
                <natgen-button class="password-toggle" appearance="hypertext-accent" @click="${x => x.togglePasswordVisibility()}">
                    ${when(x=> x.type === 'password', html`Show`)}
                    ${when(x=> x.type === 'text', html`Hide`)}
                </natgen-button>
            `)}
            ${endSlotTemplate(context, definition)}
        </div>
    </template>
`;
