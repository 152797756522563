import { stackStyles } from './stack.styles';
import { FoundationElement } from '@microsoft/fast-foundation';
import { stackTemplate } from './stack.template';
import { MediaSizeMixin } from '../../utilities/media-size-observer';
import { attr } from '@microsoft/fast-element';

export type StackAppearance = 'compact' | 'default';
// TODO: Make a generic one to be shared between similar components with directions?
export type StackDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';
export type StackAlignment = 'start' | 'center' | 'end' | 'stretch';

/**
 * @element natgen-stack
 */
export class NatGenStack extends MediaSizeMixin(FoundationElement) {
    @attr appearance: StackAppearance = 'default';
    @attr direction: StackDirection = 'column';
    @attr alignment: StackAlignment = 'start';
    @attr wrap: boolean = false;
}

export const natGenStack = NatGenStack.compose({
    baseName: 'stack',
    template: stackTemplate,
    styles: stackStyles
});
