import { parseColorHexRGB } from '@microsoft/fast-colors';
import { attr, observable } from '@microsoft/fast-element';
import { DesignToken, DesignTokenChangeRecord, FoundationElement } from '@microsoft/fast-foundation';
import { PaletteRGB, SwatchRGB } from '../../color';
import { designTokens } from '../../design-tokens';
import { paletteStyles } from './palette.styles';
import { paletteTemplate } from './palette.template';

/**
 * @element natgen-palette
 */
export class NatGenPalette extends FoundationElement {
    @attr
    color: string;
    colorChanged(previousValue: string, nextValue: string) {
        let swatch: SwatchRGB;

        if (previousValue && !previousValue.startsWith('#') && `color-${previousValue}` in designTokens)
            designTokens[`color-${previousValue}`]?.token.unsubscribe(this, this);

        if (nextValue && !nextValue.startsWith('#') && `color-${nextValue}` in designTokens)
            designTokens[`color-${nextValue}`].token.subscribe(this, this);

        this.setPalette();
    }

    setPalette() {
        let swatch: SwatchRGB;

        if (this.color.startsWith('#')) {
            const fromHex = parseColorHexRGB(this.color);
            swatch = new SwatchRGB(fromHex.r, fromHex.g, fromHex.b);
        } else {
            swatch = designTokens[`color-${this.color}`].token.getValueFor(this) as SwatchRGB;
        }

        this.target = swatch.toColorString();
        this.palette = PaletteRGB.withComponentStateColorPalette(swatch).swatches.map(s => s.toColorString());
    }

    target: string;

    @observable
    palette: string[];

    handleChange(record: DesignTokenChangeRecord<DesignToken<any>>): void {
        if (record.token.name === `color-${this.color}`) {
            // Force reload
            //this.colorChanged(this.color, this.color);
            this.setPalette();
        }
    }
}

export const natGenPalette = NatGenPalette.compose({
    baseName: 'palette',
    template: paletteTemplate,
    styles: paletteStyles
});
