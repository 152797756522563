import { ValueConverter } from '@microsoft/fast-element';

/**
 * A {@link ValueConverter} that serializes attributes to JSON string values.
 * @public
 */
export const stringListValueConverter: ValueConverter = {
    toView(value: string[]): string {
        return value.join(' ');
    },

    fromView(value: string): string[] {
        if(value === null || value === undefined || value === '')
            return [];

        return value.split(' ');
    }
}
