import { css } from '@microsoft/fast-element';
import { disabledCursor, focusVisible } from '@microsoft/fast-foundation';
import { accentFillActive, accentFillHover, accentFillRest, fillColor, foregroundOnAccentRest, neutralFillFocus, neutralForegroundRest, neutralInputFillActive, neutralInputFillHover, neutralInputFillRest, neutralStrokeActive, neutralStrokeHover, neutralStrokeRest, designTokens } from '../../design-tokens';
import { heightNumber } from '../size';

export const baseRadioStyles = css`
    :host {        
        font-size: ${designTokens['typography-type-ramp-base-font-size'].token};
        line-height: ${designTokens['typography-type-ramp-base-line-height'].token};
    }

    .control {
        --control-size: calc((${heightNumber} / 2 + ${designTokens['space-design-unit'].token}) * 1px);
        position: relative;
        width: var(--control-size);
        height: var(--control-size);
        box-sizing: border-box;
        border-radius: 999px;
        border: calc(${designTokens['space-stroke-width'].token} * 1px) solid ${neutralStrokeRest};
        background: ${neutralInputFillRest};
        outline: none;
        cursor: pointer;
    }

    .label {
        font-family: ${designTokens['typography-font-family'].token};
        color: ${neutralForegroundRest};
        padding-inline-start: calc(${designTokens['space-design-unit'].token} * 2px + 2px);
        cursor: pointer;
    }

    .label__hidden {
        display: none;
        visibility: hidden;
    }

    slot[name="checked-indicator"] {
        border-radius: 999px;
        display: flex;
        margin: auto;
        width: 16px;
        height: 100%;
        fill: ${foregroundOnAccentRest};
        opacity: 0;
        pointer-events: none;        
    }

    :host(:not([disabled])) .control:hover {
        background: ${neutralInputFillHover};
        border-color: ${neutralStrokeHover};
    }

    :host(:not([disabled])) .control:active {
        background: ${neutralInputFillActive};
        border-color: ${neutralStrokeActive};
    }

    :host(:${focusVisible}) .control {
        box-shadow: 0 0 0 2px ${fillColor}, 0 0 0 4px ${neutralFillFocus};
        border-color: ${neutralFillFocus};
    }

    :host(.checked) .control {
        background: ${accentFillRest};
        border: calc(${designTokens['space-stroke-width'].token} * 1px) solid ${accentFillRest};
    }

    :host(.checked:not([disabled])) .control:hover {
        background: ${accentFillHover};
        border: calc(${designTokens['space-stroke-width'].token} * 1px) solid ${accentFillHover};
    }

    :host(.checked:not([disabled])) .control:active {
        background: ${accentFillActive};
        border: calc(${designTokens['space-stroke-width'].token} * 1px) solid ${accentFillActive};
    }

    :host(.checked:${focusVisible}:not([disabled])) .control {
        box-shadow: 0 0 0 2px ${fillColor}, 0 0 0 4px ${neutralFillFocus};
        border-color: transparent;
    }

    :host(.disabled) .label,
    :host(.readonly) .label,
    :host(.readonly) .control,
    :host(.disabled) .control {
        cursor: ${disabledCursor};
    }

    :host([aria-checked="true"]) slot[name="checked-indicator"] {
        opacity: 1;
    }

    :host(.disabled) {
        opacity: ${designTokens['color-disabled-opacity'].token};
    }
`;
