import { css, ElementStyles } from '@microsoft/fast-element';
import { ElementDefinitionContext, FoundationElementDefinition } from '@microsoft/fast-foundation';
import { cardStyles } from '../card/card.styles';
import { radioStyles } from '../radio/radio.styles';
import { selectableCardStyles } from '../../styles/patterns/selectable-card';

export const radioCardStyles: (
    context: ElementDefinitionContext,
    definition: FoundationElementDefinition
) => ElementStyles = (
    context: ElementDefinitionContext,
    definition: FoundationElementDefinition
) => css`
    ${cardStyles(context, definition)}
    ${radioStyles(context, definition)}
    ${selectableCardStyles(context, definition)}
`;
