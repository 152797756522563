import { css, ElementStyles } from '@microsoft/fast-element';
import { ButtonOptions, disabledCursor, ElementDefinitionContext, focusVisible, forcedColorsStylesheetBehavior, FoundationElementDefinition, FoundationElementTemplate } from '@microsoft/fast-foundation';
import { SystemColors } from '@microsoft/fast-web-utilities';
import { designTokens } from '../../design-tokens';
import { appearanceBehavior } from '../../styles/behaviors';
import { AccentButtonStyles, BaseButtonStyles, HypertextAccentStyles, HypertextStyles, LightweightButtonStyles, OutlineAccentButtonStyles, OutlineButtonStyles, StealthButtonStyles } from '../../styles/patterns/button';

const interactivitySelector: string = "[href]";
const nonInteractivitySelector: string = ":not([href])";

// TODO: Secondary color styles from more composable style behaviors? #JoshD
// Perhaps separate colors from appearance?

export const anchorStyles: FoundationElementTemplate<ElementStyles, ButtonOptions> = (
    context: ElementDefinitionContext,
    definition: FoundationElementDefinition
) => css`
    :host([disabled]) {
        opacity: ${designTokens['color-disabled-opacity'].token};
        cursor: ${disabledCursor};
    }
    ${BaseButtonStyles(
        context,
        definition,
        interactivitySelector,
        nonInteractivitySelector
    )}
`.withBehaviors(
    appearanceBehavior(
        'accent',
        AccentButtonStyles(
            context,
            definition,
            interactivitySelector,
            nonInteractivitySelector
        ).withBehaviors(
            forcedColorsStylesheetBehavior(
                css`
                    :host([href]) .control {
                        background: ${SystemColors.LinkText};
                        color: ${SystemColors.HighlightText};
                    }
                    :host([href]) .control:hover {
                        background: ${SystemColors.ButtonFace};
                        border-color: ${SystemColors.LinkText};
                        box-shadow: none;
                        color: ${SystemColors.LinkText};
                        fill: currentcolor;
                    }
                    :host([href]) .control:${focusVisible} {
                        box-shadow: 0 0 0 calc((${designTokens['space-focus-stroke-width'].token} - ${designTokens['space-stroke-width'].token}) * 1px) ${SystemColors.LinkText} inset,
                          0 0 0 calc(((${designTokens['space-focus-stroke-width'].token} * 2) - ${designTokens['space-stroke-width'].token}) * 1px) ${SystemColors.HighlightText} inset ;
                    }
                `
            )
        )
    ),
    appearanceBehavior(
        'lightweight',
        LightweightButtonStyles(
            context,
            definition,
            interactivitySelector,
            nonInteractivitySelector
        )
    ),
    appearanceBehavior(
        'outline',
        OutlineButtonStyles(
            context,
            definition,
            interactivitySelector,
            nonInteractivitySelector
        )
    ),
    appearanceBehavior(
        'outline-accent',
        OutlineAccentButtonStyles(
            context,
            definition,
            interactivitySelector,
            nonInteractivitySelector
        )
    ),
    appearanceBehavior(
        'stealth',
        StealthButtonStyles(
            context,
            definition,
            interactivitySelector,
            nonInteractivitySelector
        )
    ),
    appearanceBehavior(
        'hypertext',
        HypertextStyles(
            context,
            definition,
            interactivitySelector,
            nonInteractivitySelector
        )
    ),
    appearanceBehavior(
        'hypertext-accent',
        HypertextAccentStyles(
            context,
            definition,
            interactivitySelector,
            nonInteractivitySelector
        )
    )
);
