import { css, html } from "@microsoft/fast-element";
import { FoundationElement } from "@microsoft/fast-foundation";

/**
 * @element natgen-portal-outlet
 * TODO:
 *  - Override step slotted content from parent using projection?
 *  - Animate active icon moving across states?
 */
export class NatGenWizardStepper extends FoundationElement {

}

export const natGenWizardStepper = NatGenWizardStepper.compose({
    baseName: 'wizard-stepper',
    styles: css`
        :host {
            display: flex;
        }
    `,
    template: html<NatGenWizardStepper>`
        <slot></slot>
    `
});