import { html, slotted, ViewTemplate, when } from '@microsoft/fast-element';
import { FoundationElementTemplate } from '@microsoft/fast-foundation';
import { NatGenFormField } from './form-field';

// TODO: Should tooltips be projected to a container at the provider level?
// How to project tooltip attributes onto container?

export const formFieldTemplate: FoundationElementTemplate<ViewTemplate<NatGenFormField>> = (
    context,
    definition
) => html`
    <template>
        <div>
            <slot name="validation"></slot>
            <slot name="label"></slot>
            <natgen-tooltip offset="[0, 8]" ?hidden="${x => x.slottedTooltipNodes?.length == 0}">
                <slot name="tooltip" ${slotted('slottedTooltipNodes')}></slot>
            </natgen-tooltip>
            <div class="control" part="control">
                <slot></slot>
            </div>
        </div>
    </template>
`;
