import { html, ViewTemplate, when } from '@microsoft/fast-element';
import { FoundationElementTemplate } from '@microsoft/fast-foundation';
import { NatGenHamburgerButton } from './hamburger-button';

export const hamburgerButtonTemplate: FoundationElementTemplate<ViewTemplate<NatGenHamburgerButton>> = (
    context,
    definition
) => html`
    <template class="${x => x.opened ? "opened" : "closed"}">
        <natgen-button
            class="nav-button"
            part="nav-button"
            appearance="stealth"
            @click="${(x, e) => x.toggleOpened(e.event)}"
        >
            ${when(x => !x.opened, html`<slot name="openIcon" class="openIcon"><natgen-icon name="hamburger-button" size="6"></natgen-icon></slot>`)}
            ${when(x => x.opened, html`<slot name="closeIcon" class="closeIcon"><natgen-icon name="times" size="6"></natgen-icon></slot>`)}
        </natgen-button>

        <div class="overlay" part="overlay" @click="${(x, e) => x.toggleOpened(e.event, true)}">
            <slot></slot>
        </div>
    </template>
`;
