import { css, ElementStyles } from '@microsoft/fast-element';
import { ButtonOptions, disabledCursor, ElementDefinitionContext, FoundationElementDefinition, FoundationElementTemplate } from '@microsoft/fast-foundation';
import { designTokens } from '../../design-tokens';
import { appearanceBehavior } from '../../styles/behaviors';
import { AccentButtonStyles, BaseButtonStyles, HypertextAccentStyles, HypertextStyles, LightweightButtonStyles, OutlineAccentButtonStyles, OutlineButtonStyles, StealthButtonStyles } from '../../styles/patterns/button';

const interactivitySelector: string = ':not([disabled])';
const nonInteractivitySelector: string = '[disabled]';

// TODO: Secondary color styles from more composable style behaviors? #JoshD
// Perhaps separate colors from appearance?

export const buttonStyles: FoundationElementTemplate<ElementStyles, ButtonOptions> = (
    context: ElementDefinitionContext,
    definition: FoundationElementDefinition
) => css`
    :host([disabled]) {
        opacity: ${designTokens['color-disabled-opacity'].token};
        cursor: ${disabledCursor};
    }
    ${BaseButtonStyles(
        context,
        definition,
        interactivitySelector,
        nonInteractivitySelector
    )}
`.withBehaviors(
    appearanceBehavior(
        'accent',
        AccentButtonStyles(
            context,
            definition,
            interactivitySelector,
            nonInteractivitySelector
        )
    ),
    appearanceBehavior(
        'lightweight',
        LightweightButtonStyles(
            context,
            definition,
            interactivitySelector,
            nonInteractivitySelector
        )
    ),
    appearanceBehavior(
        'outline',
        OutlineButtonStyles(
            context,
            definition,
            interactivitySelector,
            nonInteractivitySelector
        )
    ),
    appearanceBehavior(
        'outline-accent',
        OutlineAccentButtonStyles(
            context,
            definition,
            interactivitySelector,
            nonInteractivitySelector
        )
    ),
    appearanceBehavior(
        'stealth',
        StealthButtonStyles(
            context,
            definition,
            interactivitySelector,
            nonInteractivitySelector
        )
    ),
    appearanceBehavior(
        'hypertext',
        HypertextStyles(
            context,
            definition,
            interactivitySelector,
            nonInteractivitySelector
        )
    ),
    appearanceBehavior(
        'hypertext-accent',
        HypertextAccentStyles(
            context,
            definition,
            interactivitySelector,
            nonInteractivitySelector
        )
    )
);
