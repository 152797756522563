import { radioCardStyles } from './radio-card.styles';
import { applyMixins, RadioOptions, StartEnd, StartEndOptions } from '@microsoft/fast-foundation';
import { NatGenRadio } from '../radio/radio';
import { radioCardTemplate } from './radio-card.template';
import { checkIcon } from '../../styles/icons';

export type RadioCardOptions = RadioOptions & StartEndOptions;

/**
 * @element natgen-radio-card
 */
export class NatGenRadioCard extends NatGenRadio {

}

export const natGenRadioCard = NatGenRadioCard.compose<RadioCardOptions>({
    baseName: 'radio-card',
    template: radioCardTemplate,
    styles: radioCardStyles,
    checkedIndicator: checkIcon
});

applyMixins(NatGenRadioCard, StartEnd);