import { checkboxCardStyles } from './checkbox-card.styles';
import { applyMixins, CheckboxOptions, FoundationElement, StartEnd, StartEndOptions } from '@microsoft/fast-foundation';
import { NatGenCheckbox } from '../checkbox/checkbox';
import { checkboxCardTemplate } from './checkbox-card.template';
import { checkIcon, minusIcon } from '../../styles/icons';

export type CheckboxCardOptions = CheckboxOptions & StartEndOptions;

/**
 * @element natgen-checkbox-card
 */
export class NatGenCheckboxCard extends NatGenCheckbox {

}

export const natGenCheckboxCard = NatGenCheckboxCard.compose<CheckboxCardOptions>({
    baseName: 'checkbox-card',
    template: checkboxCardTemplate,
    styles: checkboxCardStyles,
    checkedIndicator: checkIcon,
    indeterminateIndicator: minusIcon
});

applyMixins(NatGenCheckboxCard, StartEnd);