import { css, ElementStyles } from "@microsoft/fast-element";
import {
    display, FoundationElementTemplate
} from "@microsoft/fast-foundation";

export const accordionStyles: FoundationElementTemplate<ElementStyles> = (
    context,
    definition
) => css`
        ${display("flex")} :host {
            box-sizing: border-box;
            flex-direction: column;
            position: relative;
        }
    `;
