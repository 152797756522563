import { radioStyles } from './radio.styles';
import { Radio, RadioOptions, radioTemplate } from '@microsoft/fast-foundation';
import { Notifier, Observable } from '@microsoft/fast-element';
import { checkIcon } from '../../styles/icons';

/**
 * @element natgen-radio
 */
export class NatGenRadio extends Radio {
    protected checkedChangedNotifier: Notifier;

    connectedCallback() {
        super.connectedCallback();

        this.checkedChangedNotifier = Observable.getNotifier(this);
        this.checkedChangedNotifier.subscribe({ handleChange: this.checkedChangeHandler.bind(this) }, 'checked');
    }

    protected checkedChangeHandler(source: NatGenRadio, propertyName: string) {
        if (propertyName === 'checked' && source.checked) {
            (source.getRootNode() as Element).querySelectorAll(`${this.tagName}[aria-checked=true][name=${source.name}]`).forEach(r => {
                if (r != source)
                    (r as NatGenRadio).checked = false;
            });
        }
    }
}

export const natGenRadio = NatGenRadio.compose<RadioOptions>({
    baseName: 'radio',
    template: radioTemplate,
    styles: radioStyles,
    checkedIndicator: checkIcon
});
