import { containerStyles } from './container.styles';
import { FoundationElement } from '@microsoft/fast-foundation';
import { containerTemplate } from './container.template';
import { MediaSizeMixin } from '../../utilities/media-size-observer';

/**
 * @element natgen-container
 */
export class NatGenContainer extends MediaSizeMixin(FoundationElement) {
}

export const natGenContainerLayout = NatGenContainer.compose({
    baseName: 'container',
    template: containerTemplate,
    styles: containerStyles
});
