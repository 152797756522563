import { attr, css, html } from "@microsoft/fast-element";
import { FoundationElement } from "@microsoft/fast-foundation";
import { accentForegroundRest, designTokens } from "../../design-tokens";
import { paddingNumber } from "../../styles/size";
import { Placement } from "@popperjs/core";
import { jsonConverter } from "../../value-converters/json-value-converter";

/**
 * @element natgen-tooltip
 */
export class NatGenTooltip extends FoundationElement {
    @attr
    placement: Placement = 'auto';
    
    @attr({ converter: jsonConverter })
    offset: [number, number];

    @attr({ mode: 'boolean' })
    flip: boolean = true;
}

export const natGenTooltip = NatGenTooltip.compose({
    baseName: 'tooltip',
    styles: css`
        .tooltip {
            vertical-align: middle;
            color: ${accentForegroundRest};
        }

        natgen-popper {
            z-index: 1;
            text-align: left;
        }

        .tooltip-container {
            --card-width: max-content;
            max-width: min(90vw, calc(${designTokens['space-breakpoint-small'].token} * 1px));
            padding: calc(${paddingNumber} * 1px);
        }
    `,
    template: html<NatGenTooltip>`
        <template>
            <natgen-button class="tooltip" appearance="lightweight">
                <natgen-icon name="tooltip" size="5"></natgen-icon>
            </natgen-button>

            <natgen-popper selector=".tooltip" offset="${x => JSON.stringify(x.offset)}" placement="${x => x.placement}" ?flip="${x => x.flip}">
                <natgen-card class="tooltip-container" part="tooltip-container">
                    <slot></slot>
                </natgen-card>
            </natgen-popper>
        </template>
    `
});