import { attr } from '@microsoft/fast-element';
import { Select, SelectOptions, selectTemplate } from '@microsoft/fast-foundation';
import { angleDown } from '../../styles/icons';
import { NatGenTextFieldAppearance } from '../text-field/text-field';
import { selectStyles } from './select.styles';

/**
 * @element natgen-select
 */
export class NatGenSelect extends Select {
    /**
     * The appearance of the element.
     *
     * @public
     * @remarks
     * HTML Attribute: appearance
     */
    @attr
    public appearance: NatGenTextFieldAppearance;

    public appearanceChanged(
        oldValue: NatGenTextFieldAppearance,
        newValue: NatGenTextFieldAppearance
    ): void {
        if (oldValue !== newValue) {
            this.classList.add(newValue);
            this.classList.remove(oldValue);
        }
    }
}

export const natGenSelect = NatGenSelect.compose<SelectOptions>({
    baseName: 'select',
    template: selectTemplate,
    styles: selectStyles,
    indicator: angleDown
});
