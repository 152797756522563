import { attr, css, html, when } from "@microsoft/fast-element";
import { Anchor, display } from "@microsoft/fast-foundation";
import { paddingNumber } from "../../styles/size";
import { accentFillRest, accentForegroundRest, designTokens, foregroundOnAccentRest, neutralFillRest } from "../../design-tokens";

export type WizardStepState = 'done' | 'active' | 'unlocked' | 'locked';

// TODO: Add label orientation (top, right, etc) and recalculate tail as needed

/**
 * @element natgen-wizard-step
 */
export class NatGenWizardStep extends Anchor {
    @attr
    state: WizardStepState = 'locked';
}

/**
 * TODO: Change active state back to an outline after Viper agrees? States shouldn't have a check if they're not done.
:host([state=active]) .marker {
    outline: calc(${designTokens['space-stroke-width'].token} * 2px) solid ${accentFillRest};
    outline-offset: calc(${designTokens['space-stroke-width'].token} * -2px)
}
 */
export const natGenWizardStep = NatGenWizardStep.compose({
    baseName: 'wizard-step',
    styles: css`
        ${display('flex')}
        
        :host {
            --marker-size: var(--wizard-marker-size, calc(${paddingNumber} * 1px));
            flex: 1;
            flex-direction: column;
            text-align: center;
        }

        .marker {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin: auto;
            width: var(--marker-size);
            height: var(--marker-size);
            border-radius: 50%;
            color: ${foregroundOnAccentRest};
            background-color: ${neutralFillRest};
            z-index: 1;
        }
       
        :host([state=done]) .marker, :host([state=unlocked]) .marker, :host([state=active]) .marker {
            background-color: ${accentFillRest};
        }

        .active-indicator {
            visibility: hidden;
            color: ${accentFillRest};
        }

        :host([state=active]) .active-indicator {
            visibility: visible;
        }

        :host(:not(:first-child)) .tail {
            --tail-size: calc(${designTokens['space-design-unit'].token} * 1px);
            position: relative;
            left: calc(-50%);
            top: calc((var(--marker-size) + var(--tail-size)) / -2);
            width: calc(100%);
            height: var(--tail-size);
            background-color: ${neutralFillRest};
        }

        :host([state=done]) .tail, :host([state=active]) .tail, :host([state=unlocked]) .tail {
            background-color: ${accentFillRest};
        }
    `,
    template: html<NatGenWizardStep>`
        <template>
            <div class="active-indicator" part="active-indicator">
                <slot name="active-indicator">
                    <natgen-icon name="solid/car-side" size="8"></natgen-icon>
                </slot>
            </div>

            <div class="container">
                <div class="marker" part="marker">
                    ${when(x => x.state == 'done' || x.state == 'active', html`
                        <slot name="icon">
                            <natgen-icon name="check" size="3"></natgen-icon>
                        </slot>
                    `)}
                </div>
                <div class="tail" part="tail"></div>
            </div>

            ${when(x => x.state == 'locked' || x.state == 'active', html<NatGenWizardStep>`
                <slot></slot>
            `, html<NatGenWizardStep>`
                <a
                    class="control"
                    part="control"
                    download="${x => x.download}"
                    href="${x => x.href}"
                    hreflang="${x => x.hreflang}"
                    ping="${x => x.ping}"
                    referrerpolicy="${x => x.referrerpolicy}"
                    rel="${x => x.rel}"
                    target="${x => x.target}"
                    type="${x => x.type}"
                    aria-atomic="${x => x.ariaAtomic}"
                    aria-busy="${x => x.ariaBusy}"
                    aria-controls="${x => x.ariaControls}"
                    aria-current="${x => x.ariaCurrent}"
                    aria-describedby="${x => x.ariaDescribedby}"
                    aria-details="${x => x.ariaDetails}"
                    aria-disabled="${x => x.ariaDisabled}"
                    aria-errormessage="${x => x.ariaErrormessage}"
                    aria-expanded="${x => x.ariaExpanded}"
                    aria-flowto="${x => x.ariaFlowto}"
                    aria-haspopup="${x => x.ariaHaspopup}"
                    aria-hidden="${x => x.ariaHidden}"
                    aria-invalid="${x => x.ariaInvalid}"
                    aria-keyshortcuts="${x => x.ariaKeyshortcuts}"
                    aria-label="${x => x.ariaLabel}"
                    aria-labelledby="${x => x.ariaLabelledby}"
                    aria-live="${x => x.ariaLive}"
                    aria-owns="${x => x.ariaOwns}"
                    aria-relevant="${x => x.ariaRelevant}"
                    aria-roledescription="${x => x.ariaRoledescription}"
                ><slot></slot></a>
            `)}
        </template>
    `
});