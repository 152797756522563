import { css, ElementStyles } from '@microsoft/fast-element';
import { ElementDefinitionContext, FoundationElementDefinition } from '@microsoft/fast-foundation';
import { cardStyles } from '../card/card.styles';
import { checkboxStyles } from '../checkbox/checkbox.styles';
import { selectableCardStyles } from '../../styles/patterns/selectable-card';

export const checkboxCardStyles: (
    context: ElementDefinitionContext,
    definition: FoundationElementDefinition
) => ElementStyles = (
    context: ElementDefinitionContext,
    definition: FoundationElementDefinition
) => css`
    ${cardStyles(context, definition)}
    ${checkboxStyles(context, definition)}
    ${selectableCardStyles(context, definition)}
`;
