import { html } from "@microsoft/fast-element";
import type { ViewTemplate } from "@microsoft/fast-element";
import { AccordionItemOptions, FoundationElementTemplate, endSlotTemplate, startSlotTemplate } from "@microsoft/fast-foundation";
import { NatGenAccordionAnchor } from "./accordion-anchor";

/**
 * The template for the {@link @microsoft/fast-foundation#(AccordionItem:class)} component.
 * @public
 */
export const accordionAnchorTemplate: FoundationElementTemplate<
    ViewTemplate<NatGenAccordionAnchor>,
    AccordionItemOptions
> = (context, definition) => html`
    <template class="${x => (x.expanded ? "expanded" : "")}">
        <div
            class="heading"
            part="heading"
            role="heading"
            aria-level="${x => x.headinglevel}"
        >
            <a
                class="button"
                part="button"
                id="${x => x.id}"
                href="${x => x.href}"
                target="${x => x.target}"
            >
                <span class="heading-content" part="heading-content">
                    <slot name="heading"></slot>
                </span>
            </a>
            ${startSlotTemplate(context, definition)}
            ${endSlotTemplate(context, definition)}
            <span class="icon" part="icon" aria-hidden="true">
                <slot name="icon" part="icon">
                    <natgen-icon name="chevron-right"></natgen-icon>
                </slot>
            <span>
        </div>
    </template>
`;