import { html, ViewTemplate } from '@microsoft/fast-element';
import { FoundationElementTemplate } from '@microsoft/fast-foundation';
import { NatGenProgressBar } from './progress-bar';

export const progressBarTemplate: FoundationElementTemplate<ViewTemplate<NatGenProgressBar>> = (
    context,
    definition
) => html`
    <div class="progressBar"></div>
`;
